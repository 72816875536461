/* ------------------------------
	// Product Variant Matrix LightBox
	------------------------------ */	
	#product-variant-matrix .table-bordered > tbody > tr > td{
		border: none;
	}

	#product-variant-matrix .rs-table {
		margin-top: 3em;
	}
	
	#product-variant-matrix tr {
		background-image: url("/App_Themes/Demonstrare2MASTER/Images/dot.png");
		background-position: center bottom;
		background-repeat: repeat-x;
		padding: 4px 0;
	}

	#product-variant-matrix th {
		background-color: #ececec;
		color: #333333;
		font-weight: normal;
		border: none;
	}

	.variant-matrix-desc1 {
		margin-top: 1.2em;
	}

	#product-variant-matrix td .StockIcon {
		/*margin-right: 5px;*/
	}
	
	#product-variant-matrix input[type="text"] {
		height: 25px;
		text-align: center;
		width: 45px;
	}

	#product-variant-matrix .btn-group.top {
		margin-bottom: 1em;
	}
	#product-variant-matrix .btn-group.bottom {
		margin-top: 1em;
	}

	#product-variant-matrix .lc-r-col1 {
		text-align: center;
	}

	#product-variant-matrix .lc-r-col5,
	#product-variant-matrix .lc-r-col6 {
		white-space: nowrap;
		text-align: right;
	}

	#product-variant-matrix-error {
		padding: 20px;
	}

/* ------------------------------
	// END - Login Contact View
	------------------------------ */

/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	.variant-matrix-desc1 {
		margin-top: 0;
	}
	#product-variant-matrix .lc-r-col3,
	#product-variant-matrix .lc-r-col7 {
		text-align: center;
	}


	#product-variant-matrix th.lc-col3,
	#product-variant-matrix th.lc-col7 {
		text-align: center;
	}

	#product-variant-matrix th.lc-col5,
	#product-variant-matrix th.lc-col6 {
		text-align: right;
	}
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
}

/*==================================================
=  END       Bootstrap 3 Media Queries             =
==================================================*/