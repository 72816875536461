/* ------------------------------
    // Cart Carriers
    ------------------------------ */

#checkout .carriers {
    margin-top: 30px;
}

#carrier-selected .panel-body .row i.icon-truck {
    font-size: 1.5em;
}

.hide-zipcode {
    display: none;
}

#zipcode-addon {
    padding: 0 2px;
}

.cart-content #zipcode-addon {
    padding: 5px 9px;
}

.cart-content input#cart-zipcode {
    padding-left: 10px;
}

#carrier-selected .panel-body .row .col-zipcode i {
    font-size: 22px;
    margin-top: 0;
    margin-right: 5px;
}

.carriers .carrier-header-text {
    font-weight: normal;
}

.carriers .carrier-show-option {
    margin-bottom: 20px;
    margin-top: -5px; /*1.5em*/
}

.carriers .carrier-header-text span {
    font-weight: bold;
}

.carriers .carrier-show-option a {
    color: #0080c9;
    font-size: 1em;
    text-decoration: underline;
    cursor: pointer;
}

.carriers .col-zipcode .smart-label {
    display: inline-block;
    margin: 0;
}

.carriers .carriers-option > div:nth-child(odd) {
    background-color: #FCFCFC;
    border-radius: 2px;
    padding: 0.5em 0 0 0;
}

.carrier-pickup-point-name {
    margin-left: 25px;
}

.carriers .carriers-option > div:nth-child(even) {
    background-color: #fff;
    padding: 0.5em 0 0 0;
}

.carriers-panel {
    margin-top: 0.5em;
    border-top: 1px dashed #dddddd;
    padding-top: 1em;
}

.carriers .panel-footer {
    font-size: 0.8em;
    padding: 3px 15px; /*10px 15px*/
}

.carriers .carrier-seleced-price {
    font-weight: bold;
    padding-right: 0.5em;
}
div.carrier-item.selected-item {
    text-align: left;
}
.carriers .carrier-seleced-price p {
    font-weight: bold;
    font-size: 1.1em;
}

.carriers i.icon-truck::before {
    color: #666666;
    font-size: 1.0em;
    margin: 0;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}

.carriers .col-post-office {
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    /*margin: -0.5em;
    font-size: 0.8em;*/
    min-height: 1.2em;
}

.checkout .carrier-item label {
    width: 100%;
    margin-right: -5em;
}

.form-control.error-input:focus {
    border-color: #FF0000;
    box-shadow: inset 0 1px 1px #000000, 0 0 8px #ff0000;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}

#carrier-zipcode {
    padding-left: 12px;
}

.carriers-pub .LayoutVersion2 .WebPubElement {
	width: 100% !important;
}


body.mc-inline-editor #carriers,
body.mc-inline-editor .carrier-show-option,
body.publish-mode #carriers,
body.publish-mode .carrier-show-option {
    display: block !important;
}

#carrier-selected .panel-body .row .checkbox i.cr-icon {
    font-size: .8em;
}

#carrier-selected .checkbox.mc-ui .cr {
    margin-left: 10px;
}

.insurance,
.coll-delivery,
.print-packing-list {
    margin-top: 5px;
}

.coll-delivery label {
    margin-left: -30px;
}

.insurance label {
    margin-left: -30px;
}

.print-packing-list label {
    margin-left: -30px;
}

.insurance .checkbox.mc-ui,
.coll-delivery .checkbox.mc-ui,
.print-packing-list .checkbox.mc-ui {
    margin-top: 0;
    margin-bottom: 0;
}

#carrier-selected .panel-body .row i#qt-insurance-i,
#carrier-selected .panel-body .row i#qt-coll-delivery-i,
.checkout .panel-body .row i#qt-print-packing-i {
    font-size: 20px;
}

.checkout .insurance .icon-help-circled,
.checkout .coll-delivery .icon-help-circled,
.checkout .print-packing-list .icon-help-circled {
    font-size: 1.3em;
}

.body-cart input#cart-zipcode {
    height: 37px;
}

.body-cart .cart-content #zipcode-addon {
    padding: 5px;
    height: 34px;
}

/* ------------------------------
    // /END - Cart Carriers
    ------------------------------ */



/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

    #carrier-selected .panel-body .row i.icon-truck {
        font-size: 2.5em;
    }

    .carriers .carrier-header-text {
        margin-top: 0.7em;
    }

    .carriers .carrier-show-option {
        margin-top: 0.7em;
        margin-bottom: 0;
    }

    .carriers .carrier-seleced-price {
        margin-top: 0;
        padding-right: 15px;
    }

    .carriers .carrier-seleced-price p {
        padding-right: 15px;
    }

    .carriers .col-post-office {
        font-size: 1em;
        margin: 0;
    }

    .carriers .panel-footer {
        padding: 8px 15px 2px 15px;
        padding-right: 0;
        font-size: 1em;
    }

    .print-packing-list label {
        margin-left: -20px;
    }

		.insurance .checkbox.mc-ui,
    .coll-delivery .checkbox.mc-ui,
    .print-packing-list .checkbox.mc-ui {
        margin-top: 10px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
}

/*==================================================
=  END       Bootstrap 3 Media Queries             =
==================================================*/
